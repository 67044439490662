.reactRating {
    display: flex !important;
    flex-wrap: nowrap;
    gap: 0.75ch;

    @media (--medium-until) {
        gap: 3%;
    }
}

.rating {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 100%;

    &.flexStart {
        justify-content: flex-start;
    }

    .item {
        width: 3rem;
        height: 3rem;

        :global(.star_svg__inner) {
            visibility: hidden;
        }

        &.filled {
            :global(.star_svg__inner) {
                visibility: visible;
            }
        }

        &.sm {
            @media (--medium) {
                width: 1.75rem;
                height: 1.75rem;
            }
        }

        &.md {
            @media (--medium) {
                width: 3rem;
                height: 3rem;
            }
        }

        &.lg {
            @media (--medium) {
                width: 8rem;
                height: 8rem;
            }
        }
    }
}
